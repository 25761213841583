/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import { Localization } from "@/commons/localization";
const L = new Localization();
export default [
  {
    url: "/overview",
    name: L.get("menu.overview"),
    slug: "overview",
    icon: "BookOpenIcon"
  },
  {
    url: null,
    name: L.get("menu.dna"),
    slug: "dna",
    icon: "ActivityIcon",
    isSuper: true,
    submenu: [
      {
        url: "/snp-list",
        name: L.get("menu.snps"),
        slug: "snps",
        icon: "GitCommitIcon"
      },
      {
        url: "/genotype-list",
        name: L.get("menu.genotypes"),
        slug: "genotype",
        icon: "DropletIcon"
      },
      {
        url: "/genoset-list",
        name: L.get("menu.genosets"),
        slug: "genoset",
        icon: "CodeIcon"
      },
      {
        url: "/genomemoset-list",
        name: L.get("menu.genomemoset"),
        slug: "genomemoset",
        icon: "AwardIcon"
      }
    ]
  },
  {
    url: null,
    name: L.get("menu.reports"),
    slug: "reports",
    icon: "ClipboardIcon",
    isSuper: true,
    submenu: [
      {
        url: "/report-list",
        name: L.get("menu.reports"),
        slug: "report",
        icon: "ActivityIcon"
      },
      {
        url: "/behaviour-list",
        name: L.get("menu.behaviours"),
        slug: "behaviour",
        icon: "StarIcon"
      },
      {
        url: "/custom-report-list",
        name: L.get("menu.custom-reports"),
        slug: "custom-reports",
        icon: "StarIcon"
      }
    ]
  },
  {
    url: null,
    name: L.get("menu.researches"),
    slug: "researches",
    icon: "CpuIcon",
    submenu: [
      {
        url: "/research-list",
        name: L.get("menu.researches"),
        slug: "researches",
        icon: "CompassIcon"
      },
      {
        url: "/correlation-list",
        name: L.get("menu.correlations"),
        slug: "correlations",
        icon: "LayersIcon"
      },
      {
        url: "/robot-list",
        name: L.get("menu.robots"),
        slug: "robots",
        icon: "CpuIcon"
      }
    ]
  },
  {
    url: null,
    name: L.get("menu.administration"),
    slug: "administration",
    icon: "BriefcaseIcon",
    submenu: [
      {
        url: "/user-list",
        name: L.get("menu.users"),
        slug: "users",
        icon: "UsersIcon"
      },
      {
        url: "/plan-list",
        name: L.get("menu.plans"),
        slug: "plans",
        icon: "BookmarkIcon",
        isSuper: true
      },
      {
        url: "/plan-code-list",
        name: L.get("menu.plans-codes"),
        slug: "plans-codes",
        icon: "AwardIcon",
        isSuper: true
      },
      {
        url: "/slot-list",
        name: L.get("menu.slots"),
        slug: "slots",
        icon: "ServerIcon",
        isSuper: true
      }
    ]
  }
];
